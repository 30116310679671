export default {
  HOME: '/',
  LOGIN: '/login',
  PROCUREMENT: '/procurement',
  PROCUREMENT_DETAILS: '/procurement/:id',
  SUPPLIES: '/supplies',
  PRODUCTION: '/production',
  PRODUCTION_DETAILS: '/production/:id',
  SALES: '/sales',
  REPORTS: '/reports',
  TRANSPORT: '/transport',

  api: {
    AUTHENTICATON: '/auth/local',
    CURRENT_USER: '/users/me',
    COMPONENTS: '/components',
    COMPONENT_TYPES: '/component-types',
    PROCUREMENTS: '/procurements',
    PRODUCTION_ORDERS: '/production-orders',
    RECIPES: '/recipes',
    STOCK_REPORT: '/stock-report',
    SENCASTLE_STOCK_REPORT: '/sencastle-stock-report',
    CUSTOMERS: '/customers',
    CUSTOMER_ORDERS: '/customer-orders',
    SENCASTLE_SALES_REPORT: '/sencastle-sales-report',
    PERMISSIONS: '/permissions',
  },

  build: (path, ...params) => {
    params.reverse();
    return path.replace(/(:\w+)/g, () => params.pop());
  },
};
