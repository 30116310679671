import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';
import { ProtectedRoute } from 'react-router-protected-switch';
import { store } from './app';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';

import { AuthSwitch } from './helpers';
import UserProvider from './providers/UserProvider';
import Dashboard from './pages/Dashboard';
import {
  Supplies,
  Login,
  Procurement,
  ProcurementDetails,
  Production,
  ProductionDetails,
  Sales,
  Transport,
  Reports,
} from './pages';
import paths from './constants/paths';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserProvider>
        <ConfigProvider>
          <Router>
            <AuthSwitch>
              <ProtectedRoute exact path={paths.HOME} component={Dashboard} />
              <ProtectedRoute
                exact
                path={paths.SUPPLIES}
                component={Supplies}
              />
              <ProtectedRoute
                exact
                path={paths.PROCUREMENT}
                component={Procurement}
              />
              <ProtectedRoute
                exact
                path={paths.PROCUREMENT_DETAILS}
                component={ProcurementDetails}
              />
              <ProtectedRoute
                exact
                path={paths.PRODUCTION}
                component={Production}
              />
              <ProtectedRoute
                exact
                path={paths.PRODUCTION_DETAILS}
                component={ProductionDetails}
              />
              <ProtectedRoute exact path={paths.SALES} component={Sales} />
              <ProtectedRoute
                exact
                path={paths.TRANSPORT}
                component={Transport}
              />
              <ProtectedRoute exact path={paths.REPORTS} component={Reports} />
            </AuthSwitch>
            <ProtectedRoute exact path={paths.LOGIN} component={Login} />
          </Router>
        </ConfigProvider>
      </UserProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
