import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { permissionsSelector } from '../features/Permissions/PermissionsSlice';

const Premissions = ({ children, action, type }) => {
  const permissions = useSelector(permissionsSelector);
  return permissions.endpoints[type][action] ? <>{children}</> : null;
};

Premissions.propTypes = {
  action: PropTypes.oneOf([
    'count',
    'create',
    'destroy',
    'destroyall',
    'find',
    'findone',
    'me',
    'permissions',
    'update',
    'updateme',
  ]),
  type: PropTypes.oneOf(['user', 'components', 'reports']),
};
export default Premissions;
