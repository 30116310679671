import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const getCurrentSencastleStockReport = createAsyncThunk(
  actions.SENCASTLE_STOCK_REPORT_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.SENCASTLE_STOCK_REPORT));
  }
);

export const suppliesSlice = createSlice({
  name: 'supplies',
  initialState: {
    currentSencastleStockReport: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.currentSencastleStockReport = null;

      return state;
    },
  },
  extraReducers: {
    [getCurrentSencastleStockReport.rejected]: apiRejectedReducer,
    [getCurrentSencastleStockReport.pending]: apiPendingReducer,
    [getCurrentSencastleStockReport.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentSencastleStockReport = payload.sencastleStockReport;
    },
  },
});

export const { clearState } = suppliesSlice.actions;

export const currentSencastleStockReportSelector = (state) =>
  state.supplies.currentSencastleStockReport;
