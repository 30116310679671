import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Typography } from 'antd';

import paths from '../../constants/paths';
import Page from '../../components/Page';
import {
  componentTypesSelector,
  getComponentTypes,
} from '../../features/ComponentTypes/ComponentTypesSlice';
import {
  procurementsSelector,
  getProcurements,
} from '../../features/Procurements/ProcurementsSlice';
import { datetimeFormatter } from '../../utils/datetime';

import './index.scss';

const { Title } = Typography;

const componentTypesColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
  },
];

const procurementsColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => datetimeFormatter(createdAt),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Details',
    dataIndex: 'link',
    key: 'link',
    render: (href) => {
      return (
        <a
          onClick={(event) => event.stopPropagation()}
          href={href}
          rel="noreferrer"
        >
          -{'>'}
        </a>
      );
    },
  },
];

const Procurement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const componentTypes = useSelector(componentTypesSelector);
  const procurements = useSelector(procurementsSelector);

  useEffect(() => {
    dispatch(getComponentTypes());
    dispatch(getProcurements());
  }, []);

  const componentTypesDataSource = useMemo(
    () =>
      componentTypes?.allComponentTypes?.map((componentType) => ({
        key: componentType.id,
        title: componentType.title,
        supplier: componentType.supplier?.name,
      })) || [],
    [componentTypes]
  );

  const procurementsDataSource = useMemo(
    () =>
      procurements?.allProcurements?.map((procurement) => ({
        key: procurement.id,
        id: procurement.id,
        title: procurement.title,
        createdAt: procurement.created_at,
        status: procurement.status,
        link: `${paths.PROCUREMENT}/${procurement.id}`,
      })) || [],
    [procurements]
  );

  return (
    <Page onBack={() => history.push(`${paths.HOME}`)}>
      <Title level={3}>Component Types</Title>
      <Table
        columns={componentTypesColumns}
        dataSource={componentTypesDataSource}
      />
      <Title level={3}>Procurements</Title>
      <Table
        columns={procurementsColumns}
        dataSource={procurementsDataSource}
      />
    </Page>
  );
};

export default Procurement;
