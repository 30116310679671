import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const getSencastleSalesReport = createAsyncThunk(
  actions.SENCASTLE_SALES_REPORT_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.SENCASTLE_SALES_REPORT));
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    currentSencastleSalesReport: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.currentSencastleSalesReport = null;

      return state;
    },
  },
  extraReducers: {
    [getSencastleSalesReport.rejected]: apiRejectedReducer,
    [getSencastleSalesReport.pending]: apiPendingReducer,
    [getSencastleSalesReport.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentSencastleSalesReport = payload.sencastleSalesReport;
    },
  },
});

export const { clearState } = reportsSlice.actions;

export const reportsSelector = (state) => state.reports;
