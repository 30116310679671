import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, Typography } from 'antd';

import paths from '../../constants/paths';
import Page from '../../components/Page';
import {
  getProductionOrders,
  productionOrdersSelector,
  getCurrentRecipe,
  currentRecipeSelector,
} from '../../features/Production/ProductionSlice';
import { datetimeFormatter } from '../../utils/datetime';

import './index.scss';

const { Title } = Typography;

const productionOrdersColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Deadline',
    dataIndex: 'deadline',
    key: 'deadline',
    render: (datetime) => datetimeFormatter(datetime),
  },
  {
    title: 'Details',
    dataIndex: 'link',
    key: 'link',
    render: (href) => {
      return (
        <a
          onClick={(event) => event.stopPropagation()}
          href={href}
          rel="noreferrer"
        >
          -{'>'}
        </a>
      );
    },
  },
];

const recipeColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Component Type',
    dataIndex: 'componentType',
    key: 'componentType',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Average delivery time (days)',
    dataIndex: 'averageDeliveryTime',
    key: 'averageDeliveryTime',
  },
];

const Production = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const productionOrders = useSelector(productionOrdersSelector);
  const currentRecipe = useSelector(currentRecipeSelector);
  useEffect(() => {
    dispatch(getProductionOrders());
    dispatch(getCurrentRecipe());
  }, []);

  const productionOrdersDataSource = useMemo(
    () =>
      productionOrders?.map((productionOrder) => ({
        key: productionOrder.id,
        title: productionOrder.title,
        deadline: productionOrder.deadline,
        link: `${paths.PRODUCTION}/${productionOrder.id}`,
      })) || [],
    [productionOrders]
  );

  const recipeDataSource = useMemo(
    () =>
      currentRecipe?.component_list?.map((componentListItem) => ({
        key: componentListItem.id,
        id: componentListItem.id,
        componentType: componentListItem.component_type.title,
        supplier: componentListItem.component_type?.supplier?.name,
        quantity: componentListItem.quantity,
        averageDeliveryTime:
          componentListItem.component_type.average_delivery_time,
      })) || [],
    [currentRecipe]
  );

  return (
    <Page onBack={() => history.push(`${paths.HOME}`)}>
      <Title level={3}>Production Orders</Title>
      <Table
        columns={productionOrdersColumns}
        dataSource={productionOrdersDataSource}
      />
      <Title level={3}>Recipe</Title>
      <Table
        columns={recipeColumns}
        dataSource={recipeDataSource}
        pagination={false}
      />
    </Page>
  );
};

export default Production;
