import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Typography } from 'antd';

import Page from '../../components/Page';
import paths from '../../constants/paths';
import {
  getCustomerOrders,
  transportSelector,
} from '../../features/Transport/TransportSlice';

import './index.scss';

const { Title } = Typography;

const customerOrdersColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Tracking ID',
    dataIndex: 'trackingId',
    key: 'trackingId',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const StatusMapping = {
  Ordered: 'Ordered',
  OrderPreparation: 'In preparation',
  Shipped: 'Shipped',
};

function roundToTwo(num) {
  return +`${Math.round(`${num}e+2`)}e-2`;
}

const Transport = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const transport = useSelector(transportSelector);

  useEffect(() => {
    dispatch(getCustomerOrders());
  }, []);

  const customerOrdersDataSource = useMemo(
    () =>
      transport?.allCustomerOrders?.map((customerOrder) => ({
        key: customerOrder.id,
        id: customerOrder.id,
        customer: customerOrder.customer?.name || 'None',
        customerObj: customerOrder.customer,
        quantity: customerOrder.quantity,
        trackingId: customerOrder.trackingId,
        status: StatusMapping[customerOrder.status] || 'Invalid status',
      })) || [],
    [transport]
  );

  return (
    <Page onBack={() => history.push(`${paths.HOME}`)}>
      <Title level={3}>Customer Orders</Title>
      <Table
        columns={customerOrdersColumns}
        dataSource={customerOrdersDataSource}
        rowClassName={(record) => {
          const customerRating = parseFloat(
            record?.customerObj?.averageCollectionPeriod
          )
            ? roundToTwo(
                100 *
                  (parseFloat(record.customerObj.liquidityRatio) /
                    parseFloat(record.customerObj.averageCollectionPeriod))
              )
            : 0;

          if (!customerRating) {
            return;
          }

          return customerRating < 3 ? 'transport-customer-red' : '';
        }}
      />
    </Page>
  );
};

export default Transport;
