import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const createComponent = createAsyncThunk(
  actions.COMPONENT_CREATE,
  async (data, thunkAPI) => {
    return baseThunk(thunkAPI, api.post(paths.api.COMPONENTS, data));
  }
);
export const updateComponentById = createAsyncThunk(
  actions.COMPONENT_UPDATE_BY_ID,
  async (data, thunkAPI) => {
    return baseThunk(
      thunkAPI,
      api.update(paths.api.COMPONENTS`/${data.id}`, data)
    );
  }
);
export const getComponents = createAsyncThunk(
  actions.COMPONENTS_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.COMPONENTS));
  }
);
export const getComponentById = createAsyncThunk(
  actions.COMPONENT_GET_BY_ID,
  async (data, thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.COMPONENTS`/${data.id}`));
  }
);
export const deleteComponentById = createAsyncThunk(
  actions.COMPONENT_DELETE_BY_ID,
  async (data, thunkAPI) => {
    return baseThunk(
      thunkAPI,
      api.deleteItem(paths.api.COMPONENTS`/${data.id}`)
    );
  }
);

export const componentSlice = createSlice({
  name: 'component',
  initialState: {
    allComponents: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.allComponents = null;

      return state;
    },
  },
  extraReducers: {
    [getComponents.rejected]: apiRejectedReducer,
    [getComponents.pending]: apiPendingReducer,
    [getComponents.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.allComponents = payload;
    },
  },
});

export const { clearState } = componentSlice.actions;

export const componentSelector = (state) => state.component;
