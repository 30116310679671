import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Typography } from 'antd';

import Page from '../../components/Page';
import paths from '../../constants/paths';
import {
  getCurrentStockReport,
  currentStockReportSelector,
} from '../../features/Production/ProductionSlice';
import {
  getCurrentSencastleStockReport,
  currentSencastleStockReportSelector,
} from '../../features/Supplies/SuppliesSlice';

import './index.scss';

const { Title } = Typography;

const suppliesColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Component Type',
    dataIndex: 'componentType',
    key: 'componentType',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
];

const sencastlesColumns = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
];

const StatusMapping = {
  InProduction: 'In Production',
  InWarehouse: 'In Warehouse',
  Shipped: 'Shipped',
};

const Supplies = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentStockReport = useSelector(currentStockReportSelector);
  const currentSencastleStockReport = useSelector(
    currentSencastleStockReportSelector
  );

  useEffect(() => {
    dispatch(getCurrentStockReport());
    dispatch(getCurrentSencastleStockReport());
  }, []);

  const suppliesDataSource = useMemo(
    () =>
      currentStockReport?.map((stockReportItem) => ({
        key: stockReportItem.component_type,
        id: stockReportItem.component_type,
        componentType: stockReportItem.title,
        supplier: stockReportItem.name,
        quantity: stockReportItem.num_components,
      })) || [],
    [currentStockReport]
  );
  const sencastlesDataSource = useMemo(
    () =>
      currentSencastleStockReport?.map((stockReportItem) => ({
        key: stockReportItem.status,
        status: StatusMapping?.[stockReportItem.status] || 'Invalid status',
        quantity: stockReportItem.num_sencastles,
      })),
    [currentSencastleStockReport]
  );

  return (
    <Page onBack={() => history.push(`${paths.HOME}`)}>
      <Title level={3}>Supplies</Title>
      <Table columns={suppliesColumns} dataSource={suppliesDataSource} />
      <Title level={3}>SENcastles</Title>
      <Table columns={sencastlesColumns} dataSource={sencastlesDataSource} />
    </Page>
  );
};

export default Supplies;
