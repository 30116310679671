import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const getProductionOrders = createAsyncThunk(
  actions.PRODUCTION_ORDERS_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.PRODUCTION_ORDERS));
  }
);

export const getProductionOrderById = createAsyncThunk(
  actions.PRODUCTION_ORDERS_GET_BY_ID,
  async (id, thunkAPI) => {
    return baseThunk(thunkAPI, api.get(`${paths.api.PRODUCTION_ORDERS}/${id}`));
  }
);

export const getCurrentRecipe = createAsyncThunk(
  actions.RECIPES_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.RECIPES));
  }
);

export const getCurrentStockReport = createAsyncThunk(
  actions.STOCK_REPORT_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.STOCK_REPORT));
  }
);

export const productionSlice = createSlice({
  name: 'production',
  initialState: {
    allProductionOrders: null,
    currentProductionOrder: null,
    currentRecipe: null,
    currentStockReport: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.allProductionOrders = null;
      state.currentProductionOrder = null;
      state.currentRecipe = null;
      state.currentStockReport = null;

      return state;
    },
  },
  extraReducers: {
    [getProductionOrders.rejected]: apiRejectedReducer,
    [getProductionOrders.pending]: apiPendingReducer,
    [getProductionOrders.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.allProductionOrders = payload;
    },
    [getProductionOrderById.rejected]: apiRejectedReducer,
    [getProductionOrderById.pending]: apiPendingReducer,
    [getProductionOrderById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentProductionOrder = payload;
    },
    [getCurrentRecipe.rejected]: apiRejectedReducer,
    [getCurrentRecipe.pending]: apiPendingReducer,
    [getCurrentRecipe.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentRecipe = payload && payload[0];
    },
    [getCurrentStockReport.rejected]: apiRejectedReducer,
    [getCurrentStockReport.pending]: apiPendingReducer,
    [getCurrentStockReport.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentStockReport = payload.stockReport;
    },
  },
});

export const { clearState } = productionSlice.actions;

export const productionOrdersSelector = (state) =>
  state.production.allProductionOrders;

export const currentProductionOrderSelector = (state) =>
  state.production.currentProductionOrder;

export const currentRecipeSelector = (state) => state.production.currentRecipe;

export const currentStockReportSelector = (state) =>
  state.production.currentStockReport;
