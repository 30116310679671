import paths from './paths';
import procurement from '../images/dashboard/procurement-min.jpg';
import supplies from '../images/dashboard/supplies-min.jpg';
import purchase from '../images/dashboard/purchase-min.jpg';
import salesTransport from '../images/dashboard/salesTransport-min.jpg';
import production from '../images/dashboard/production-min.jpg';
import reports from '../images/dashboard/reports-min.jpg';

const menuLabels = [
  {
    label: 'Procurement',
    path: paths.PROCUREMENT,
    srcImage: procurement,
  },
  {
    label: 'Production',
    path: paths.PRODUCTION,
    srcImage: production,
  },
  {
    label: 'Supplies',
    path: paths.SUPPLIES,
    srcImage: supplies,
  },
  {
    label: 'Sales',
    path: paths.SALES,
    srcImage: purchase,
  },
  {
    label: 'Transport',
    path: paths.TRANSPORT,
    srcImage: salesTransport,
  },
  {
    label: 'Reports',
    path: paths.REPORTS,
    srcImage: reports,
  },
];

export default menuLabels;
