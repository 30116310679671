export default {
  USER_LOGIN: 'users/login',
  USER_FETCH_BY_TOKEN: 'users/fetchUserByToken',
  USER_UPDATE: 'users/update',
  COMPONENTS_GET: 'components/get',
  COMPONENT_GET_BY_ID: 'component/getById',
  COMPONENT_CREATE: 'component/create',
  COMPONENT_DELETE_BY_ID: 'component/deleteById',
  COMPONENT_UPDATE_BY_ID: 'component/updateById',
  COMPONENT_TYPES_GET: 'component-types/get',
  PROCUREMENTS_GET: 'procurements/get',
  PROCUREMENTS_GET_BY_ID: 'procurements/getById',
  PRODUCTION_ORDERS_GET: 'production-orders/get',
  PRODUCTION_ORDERS_GET_BY_ID: 'production-orders/getById',
  RECIPES_GET: 'recipes/get',
  RECIPES_GET_BY_ID: 'recipes/getById',
  STOCK_REPORT_GET: 'stock-report/get',
  SENCASTLE_STOCK_REPORT_GET: 'sencastle-stock-report/get',
  CUSTOMERS_GET: 'customers/get',
  CUSTOMER_ORDERS_GET: 'customer-orders/get',
  SENCASTLE_SALES_REPORT_GET: 'sencastle-sales-report/get',
  PERMISSIONS_FETCH: 'permissions/fetch',
};
