import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Row, Col, Card, Statistic } from 'antd';
import moment from 'moment';
import minBy from 'lodash/minBy';
import { Line } from '@ant-design/charts';
import '@ant-design/charts/dist/index.css';

import Page from '../../components/Page';
import paths from '../../constants/paths';
import {
  getCurrentSencastleStockReport,
  currentSencastleStockReportSelector,
} from '../../features/Supplies/SuppliesSlice';
import {
  getSencastleSalesReport,
  reportsSelector,
} from '../../features/Reports/ReportsSlice';

import './index.scss';

const { Title } = Typography;

const Reports = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentSencastleStockReport = useSelector(
    currentSencastleStockReportSelector
  );
  const reports = useSelector(reportsSelector);

  useEffect(() => {
    dispatch(getCurrentSencastleStockReport());
    dispatch(getSencastleSalesReport());
  }, []);

  const sencastleStats = useMemo(
    () => ({
      inWarehouse:
        currentSencastleStockReport?.find(
          (stockReportItem) => stockReportItem.status === 'InWarehouse'
        )?.num_sencastles || 0,
      shipped:
        currentSencastleStockReport?.find(
          (stockReportItem) => stockReportItem.status === 'Shipped'
        )?.num_sencastles || 0,
    }),
    [currentSencastleStockReport]
  );

  const sencastleSalesStats = useMemo(() => {
    const filteredRows =
      reports?.currentSencastleSalesReport?.filter(
        (salesReportItem) => salesReportItem.month && salesReportItem.year
      ) || [];

    if (!filteredRows.length) {
      return [];
    }

    const currentMonthNumber = parseFloat(moment().format('M'));
    const currentYearNumber = parseFloat(moment().format('YYYY'));
    const oldestMonth = minBy(filteredRows, (o) => {
      return parseFloat(o.month);
    })?.month;
    const numOfMonths = currentMonthNumber - oldestMonth + 1;
    const months = Array(numOfMonths)
      .fill()
      .map((_, i) => oldestMonth + i);

    const years = months.map((month) => ({
      Date: `${currentYearNumber}-${month}`,
      Sales: 0,
    }));

    const formattedFilteredRows = filteredRows.map((salesReportItem) => ({
      Date: `${salesReportItem.year}-${salesReportItem.month}`,
      Sales: parseFloat(salesReportItem.count),
    }));

    const final = years.map((year) => {
      const foundRow = formattedFilteredRows.find(
        (formattedFilteredRow) => formattedFilteredRow.Date === year.Date
      );

      if (foundRow) {
        return foundRow;
      }

      return year;
    });

    return final;
  }, [reports]);

  return (
    <Page onBack={() => history.push(`${paths.HOME}`)}>
      <Title level={3}>Reports</Title>
      <Title level={4}>SENcastle Statistics</Title>
      <div className="reports-statistic-card">
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Statistic
                title="In Warehouse"
                value={sencastleStats.inWarehouse}
                precision={0}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Shipped"
                value={sencastleStats.shipped}
                precision={0}
              />
            </Card>
          </Col>
        </Row>
        <Title level={4} style={{ marginTop: '1.2em' }}>
          SENcastle Sales per Month
        </Title>
        <Line
          key={sencastleSalesStats}
          data={sencastleSalesStats}
          padding="auto"
          xField="Date"
          yField="Sales"
          xAxis={{ tickCount: 5 }}
          yAxis={{
            grid: { line: { stroke: '#f0f0f0' } },
            tickInterval: 1,
          }}
        />
      </div>
    </Page>
  );
};

export default Reports;
