import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const getCustomerOrders = createAsyncThunk(
  actions.CUSTOMER_ORDERS_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.CUSTOMER_ORDERS));
  }
);

export const transportSlice = createSlice({
  name: 'transport',
  initialState: {
    allCustomerOrders: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.allCustomerOrders = null;

      return state;
    },
  },
  extraReducers: {
    [getCustomerOrders.rejected]: apiRejectedReducer,
    [getCustomerOrders.pending]: apiPendingReducer,
    [getCustomerOrders.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.allCustomerOrders = payload;
    },
  },
});

export const { clearState } = transportSlice.actions;

export const transportSelector = (state) => state.transport;
