import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Button, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import paths from '../../constants/paths';

import {
  loginUser,
  userSelector,
  clearState,
} from '../../features/User/UserSlice';

import './index.scss';

const Login = () => {
  const dispatch = useDispatch();
  const [errorLogin, setErrorLogin] = useState(false);
  const user = useSelector(userSelector);
  const [form, setForm] = useState(user);

  const onChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };
  const history = useHistory();
  const { isSuccess, isError, errorMessage } = useSelector(userSelector);

  const onSubmit = (data) => {
    dispatch(loginUser(data));
  };
  useEffect(() => {
    if (isError) {
      setErrorLogin(true);
      toast.error(errorMessage);
      dispatch(clearState());
    }
    if (isSuccess) {
      history.push(`${paths.HOME}`);
    }
  }, [isSuccess, isError]);
  return (
    <div className="login-container">
      <Form
        labelCol={{
          span: 8,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Form.Item
          onChange={onChange}
          label="Email"
          name="identifier"
          rules={[
            {
              required: true,
              message: 'Please input your e-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          onChange={onChange}
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        />

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>
      </Form>
      {errorLogin && (
        <Alert
          message="E-mail and/or password are not valid!"
          showIcon
          description="Please enter a valid e-mail and/or password and try again."
          type="error"
          closable
          onClick={() => setErrorLogin(false)}
        />
      )}
    </div>
  );
};

export default Login;
