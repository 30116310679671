import { loginUser } from '../features/User/UserSlice';

const localStorageMiddleware = () => (next) => (action) => {
  if (action.type === `${loginUser.fulfilled}`) {
    localStorage.setItem('token', action.payload.jwt);
  }
  return next(action);
};

export default localStorageMiddleware;
