import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const getCustomers = createAsyncThunk(
  actions.CUSTOMERS_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.CUSTOMERS));
  }
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    allCustomers: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.allCustomers = null;

      return state;
    },
  },
  extraReducers: {
    [getCustomers.rejected]: apiRejectedReducer,
    [getCustomers.pending]: apiPendingReducer,
    [getCustomers.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.allCustomers = payload;
    },
  },
});

export const { clearState } = customersSlice.actions;

export const customersSelector = (state) => state.customers;
