import paths from './paths';

const navbarLabels = [
  {
    label: 'SENcastle',
    path: paths.HOME,
  },
  {
    label: 'Procurement',
    path: paths.PROCUREMENT,
  },
  {
    label: 'Production',
    path: paths.PRODUCTION,
  },
  {
    label: 'Supplies',
    path: paths.SUPPLIES,
  },
  {
    label: 'Sales',
    path: paths.SALES,
  },
  {
    label: 'Transport',
    path: paths.TRANSPORT,
  },
  {
    label: 'Reports',
    path: paths.REPORTS,
  },
];

export default navbarLabels;
