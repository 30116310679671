import React from 'react';
import { Layout, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Navbar } from '..';

import eglasLogo from '../../images/logos/eglas_logo.png';
import sencastleLogo from '../../images/logos/sencastle_logo.png';

import './index.scss';

const { Content, Footer } = Layout;

const Page = (props) => {
  const { onBack, children } = props;

  return (
    <Layout style={{ minHeight: '100vh', background: 'white' }}>
      <Navbar />
      <Content>
        <div className="layout-content">
          {onBack && (
            <div className="layout-navigation">
              <Button
                type="primary"
                size="large"
                icon={<ArrowLeftOutlined />}
                onClick={onBack}
              />
            </div>
          )}
          {children}
        </div>
      </Content>
      <Footer className="layout-footer">
        <img src={eglasLogo} />
        <div className="layout-footer-horizontal-space" />
        <img src={sencastleLogo} />
      </Footer>
    </Layout>
  );
};

export default Page;
