import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const getProcurements = createAsyncThunk(
  actions.PROCUREMENTS_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.PROCUREMENTS));
  }
);

export const getProcurementById = createAsyncThunk(
  actions.PROCUREMENTS_GET_BY_ID,
  async (id, thunkAPI) => {
    return baseThunk(thunkAPI, api.get(`${paths.api.PROCUREMENTS}/${id}`));
  }
);

export const procurementsSlice = createSlice({
  name: 'procurements',
  initialState: {
    allProcurements: null,
    currentProcurement: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.allProcurements = null;
      state.currentProcurement = null;

      return state;
    },
  },
  extraReducers: {
    [getProcurements.rejected]: apiRejectedReducer,
    [getProcurements.pending]: apiPendingReducer,
    [getProcurements.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.allProcurements = payload;
    },
    [getProcurementById.rejected]: apiRejectedReducer,
    [getProcurementById.pending]: apiPendingReducer,
    [getProcurementById.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.currentProcurement = payload;
    },
  },
});

export const { clearState } = procurementsSlice.actions;

export const procurementsSelector = (state) => state.procurements;
export const currentProcurementSelector = (state) =>
  state.procurements.currentProcurement;
