const apiFetch = (path, data, method) => {
  const userToken = localStorage.getItem('token');
  const authorization = userToken && `Bearer ${userToken}`;
  const body = data ? JSON.stringify({ ...data }) : null;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (authorization) {
    headers.Authorization = [authorization];
  }
  const response = fetch(`${process.env.REACT_APP_API_BASE_URL}${path}`, {
    method,
    headers,
    body,
  });
  return response;
};

const get = (path) => {
  return apiFetch(path, null, 'GET');
};
const post = (path, data) => {
  return apiFetch(path, data, 'POST');
};
const update = (path, data) => {
  return apiFetch(path, data, 'PUT');
};
const deleteItem = (path, data) => {
  return apiFetch(path, data, 'DELETE');
};
const api = {
  get,
  post,
  update,
  deleteItem,
};

export default api;
