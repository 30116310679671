import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Typography, Checkbox } from 'antd';

import Page from '../../components/Page';
import {
  getProductionOrderById,
  currentProductionOrderSelector,
  getCurrentStockReport,
  currentStockReportSelector,
} from '../../features/Production/ProductionSlice';
import paths from '../../constants/paths';
import { datetimeFormatter } from '../../utils/datetime';

import './index.scss';

const { Title } = Typography;

const productionOrdersColumns = [
  {
    title: 'Component Type',
    dataIndex: 'componentType',
    key: 'componentType',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
  },
  {
    title: 'Production Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Available Quantity',
    dataIndex: 'availableQuantity',
    key: 'availableQuantity',
  },
  {
    title: 'Missing Quantity',
    dataIndex: 'missingQuantity',
    key: 'missingQuantity',
  },
];

const ProductionDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentProductionOrder = useSelector(currentProductionOrderSelector);
  const currentStockReport = useSelector(currentStockReportSelector);

  useEffect(() => {
    dispatch(getProductionOrderById(id));
    dispatch(getCurrentStockReport());
  }, [id]);

  const productionOrdersDataSource = useMemo(
    () =>
      (currentStockReport &&
        currentProductionOrder &&
        currentStockReport
          .filter((stockReportItem) =>
            currentProductionOrder.recipe.component_list.some(
              (componentListItem) =>
                componentListItem.component_type.id ===
                stockReportItem.component_type
            )
          )
          .map((stockReportItem) => {
            const recipeQuantity =
              currentProductionOrder.recipe.component_list.find(
                (component) =>
                  component.component_type.id === stockReportItem.component_type
              ).quantity;

            const missingQuantity =
              parseInt(recipeQuantity, 10) -
              parseInt(stockReportItem.quantity, 10);

            const missingQuantityToDisplay =
              missingQuantity > 0 ? missingQuantity : 0;

            return {
              key: stockReportItem.component_type,
              componentType: stockReportItem.title,
              supplier: stockReportItem.name,
              quantity: recipeQuantity,
              availableQuantity: stockReportItem.num_components,
              missingQuantity: missingQuantityToDisplay,
            };
          })) ||
      [],
    [currentStockReport, currentProductionOrder]
  );

  return (
    <Page onBack={() => history.push(`${paths.PRODUCTION}`)}>
      <Title level={3}>Production Details</Title>
      <Title level={4}>Title</Title>
      <Typography.Paragraph>
        {currentProductionOrder?.title}
      </Typography.Paragraph>
      <Title level={4}>Deadline</Title>
      {datetimeFormatter(currentProductionOrder?.deadline)}
      <Title level={4}>Current Stock</Title>
      <Table
        columns={productionOrdersColumns}
        dataSource={productionOrdersDataSource}
      />
      <Title level={4}>Production Checklist</Title>
      <div className="production-checklist">
        {currentProductionOrder?.step_list?.map((stepItem) => (
          <Checkbox checked={stepItem.check}>{stepItem.title}</Checkbox>
        ))}
      </div>
    </Page>
  );
};

export default ProductionDetails;
