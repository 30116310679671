import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Typography } from 'antd';

import Page from '../../components/Page';
import {
  getProcurementById,
  currentProcurementSelector,
} from '../../features/Procurements/ProcurementsSlice';
import paths from '../../constants/paths';

import './index.scss';

const { Title } = Typography;

const componentsColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Component Type',
    dataIndex: 'componentType',
    key: 'componentType',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Average delivery time (days)',
    dataIndex: 'averageDeliveryTime',
    key: 'averageDeliveryTime',
  },
];

const ProcurementDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentProcurement = useSelector(currentProcurementSelector);

  useEffect(() => {
    dispatch(getProcurementById(id));
  }, [id]);

  const componentsDataSource = useMemo(
    () =>
      currentProcurement?.component_list?.map((componentListItem) => ({
        key: componentListItem.id,
        id: componentListItem.id,
        componentType: componentListItem.component_type.title,
        supplier: componentListItem.component_type?.supplier?.name,
        quantity: componentListItem.quantity,
        status: currentProcurement.status,
        averageDeliveryTime:
          componentListItem.component_type.average_delivery_time,
      })) || [],
    [currentProcurement]
  );

  return (
    <Page onBack={() => history.push(`${paths.PROCUREMENT}`)}>
      <Title level={3}>Procurement Details</Title>
      <Table columns={componentsColumns} dataSource={componentsDataSource} />
    </Page>
  );
};

export default ProcurementDetails;
