import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import 'antd/dist/antd.css';
import { Card, Row, Col } from 'antd';

import Page from '../../components/Page';
import './index.scss';
import { userSelector } from '../../features/User/UserSlice';
import { menuLabels } from '../../constants';

const Dashboard = () => {
  const { Meta } = Card;
  const { isFetching } = useSelector(userSelector);
  return (
    <Page>
      <>
        {isFetching ? (
          <Loader type="Puff" className="ant-loader" />
        ) : (
          <Row gutter={16} justify="space-between">
            {menuLabels.map((menu) => {
              return (
                <Col className="gutter-row" span={8}>
                  <Link to={menu.path}>
                    <Card
                      hoverable
                      className="dashboard-card"
                      cover={
                        <img
                          src={menu.srcImage}
                          className="dashboard-card-image"
                        />
                      }
                    >
                      <Meta title={menu.label} />
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        )}
      </>
    </Page>
  );
};

export default Dashboard;
