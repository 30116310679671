import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const fetchPermsissions = createAsyncThunk(
  actions.PERMISSIONS_FETCH,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.PERMISSIONS));
  }
);
export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    endpoints: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    isLoaded: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isLoaded = false;
      state.endpoints = null;

      return state;
    },
  },
  extraReducers: {
    [fetchPermsissions.rejected]: apiRejectedReducer,
    [fetchPermsissions.pending]: apiPendingReducer,
    [fetchPermsissions.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.endpoints = payload;
      state.isLoaded = state.endpoints && state.isSuccess;
      return state;
    },
  },
});

export const { clearState } = permissionsSlice.actions;

export const permissionsSelector = (state) => state.permissions;
