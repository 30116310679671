import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';

export const baseThunk = async (thunkAPI, promise) => {
  const response = await promise;
  try {
    if (response.status === 200) {
      return response.json();
    } else {
      return thunkAPI.rejectWithValue(response.json());
    }
  } catch (e) {
    console.error('Error', e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
};
export const loginUser = createAsyncThunk(
  actions.USER_LOGIN,
  async (data, thunkAPI) => {
    return baseThunk(thunkAPI, api.post(paths.api.AUTHENTICATON, data));
  }
);
export const updateUser = createAsyncThunk(
  actions.USER_UPDATE,
  async (data, thunkAPI) => {
    return baseThunk(thunkAPI, api.update(paths.api.CURRENT_USER, data));
  }
);
export const fetchUserBytoken = createAsyncThunk(
  actions.USER_FETCH_BY_TOKEN,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.CURRENT_USER));
  }
);
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: null,
    email: null,
    fullName: null,
    id: null,
    role: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    isLoaded: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isLoaded = false;
      state.username = null;
      state.email = null;

      return state;
    },
  },
  extraReducers: {
    [loginUser.rejected]: apiRejectedReducer,
    [loginUser.pending]: apiPendingReducer,
    [loginUser.fulfilled]: (state, { payload }) => {
      state.email = payload.email;
      state.username = payload.name;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },

    [fetchUserBytoken.rejected]: apiRejectedReducer,
    [fetchUserBytoken.pending]: apiPendingReducer,
    [fetchUserBytoken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.username = payload.username;
      state.fullName = payload.fullName;
      state.id = payload.id;
      state.role = payload.role;
      state.endpoints = payload.endpoints;
      state.isLoaded = state.username && state.isSuccess;
    },
    [updateUser.rejected]: apiRejectedReducer,
    [updateUser.pending]: apiPendingReducer,
    [updateUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload.email;
      state.username = payload.username;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
