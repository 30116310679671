import React from 'react';
import { SwitchContext } from 'react-router-protected-switch';
import { useSelector } from 'react-redux';

import { userSelector } from '../features/User/UserSlice';

const AuthSwitch = ({ children }) => {
  const user = useSelector(userSelector);
  const redirect = !user.username && '/login';
  return <SwitchContext value={redirect}>{children}</SwitchContext>;
};

export default AuthSwitch;
