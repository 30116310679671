import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from '../features/User/UserSlice';
import { permissionsSlice } from '../features/Permissions/PermissionsSlice';
import { componentSlice } from '../features/Components/ComponentsSlice';
import { componentTypesSlice } from '../features/ComponentTypes/ComponentTypesSlice';
import { procurementsSlice } from '../features/Procurements/ProcurementsSlice';
import { productionSlice } from '../features/Production/ProductionSlice';
import { suppliesSlice } from '../features/Supplies/SuppliesSlice';
import { customersSlice } from '../features/Customers/CustomersSlice';
import { transportSlice } from '../features/Transport/TransportSlice';
import { reportsSlice } from '../features/Reports/ReportsSlice';

import { localStorageMiddleware } from '../middlewares';

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    permissions: permissionsSlice.reducer,
    component: componentSlice.reducer,
    componentTypes: componentTypesSlice.reducer,
    procurements: procurementsSlice.reducer,
    production: productionSlice.reducer,
    supplies: suppliesSlice.reducer,
    customers: customersSlice.reducer,
    transport: transportSlice.reducer,
    reports: reportsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});
