import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUserBytoken, userSelector } from '../../features/User/UserSlice';
import {
  fetchPermsissions,
  permissionsSelector,
} from '../../features/Permissions/PermissionsSlice';

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();
  const userToken = localStorage.getItem('token');
  const user = useSelector(userSelector);
  const permissions = useSelector(permissionsSelector);

  useEffect(() => {
    if (userToken) {
      dispatch(fetchUserBytoken());
      dispatch(fetchPermsissions());
    }
  }, [userToken]);

  if ((user.isLoaded && permissions.isLoaded) || !userToken)
    return <>{children}</>;
  return null;
};
export default UserProvider;
