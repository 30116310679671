import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Typography } from 'antd';

import Page from '../../components/Page';
import paths from '../../constants/paths';
import {
  getCustomers,
  customersSelector,
} from '../../features/Customers/CustomersSlice';

import './index.scss';

const { Title } = Typography;

const customersColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Average Collection Period ',
    dataIndex: 'averageCollectionPeriod',
    key: 'averageCollectionPeriod',
  },
  {
    title: 'Liquidity Ratio',
    dataIndex: 'liquidityRatio',
    key: 'liquidityRatio',
  },
  {
    title: 'Customer Rating',
    dataIndex: 'customerRating',
    key: 'customerRating',
    render: (customerRating) => {
      let fontColor = customerRating && customerRating < 3 ? 'red' : 'green';

      if (!customerRating) {
        fontColor = 'black';
      }

      return (
        <span
          style={{
            color: fontColor,
          }}
        >
          {customerRating}
        </span>
      );
    },
  },
];

function roundToTwo(num) {
  return +`${Math.round(`${num}e+2`)}e-2`;
}

const Sales = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const customers = useSelector(customersSelector);

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  const customersDataSource = useMemo(
    () =>
      customers?.allCustomers?.map((customer) => ({
        key: customer.id,
        id: customer.id,
        name: customer.name,
        averageCollectionPeriod: customer.averageCollectionPeriod,
        liquidityRatio: customer.liquidityRatio,
        customerRating: parseFloat(customer.averageCollectionPeriod)
          ? roundToTwo(
              100 *
                (parseFloat(customer.liquidityRatio) /
                  parseFloat(customer.averageCollectionPeriod))
            )
          : 0,
      })) || [],
    [customers]
  );

  return (
    <Page onBack={() => history.push(`${paths.HOME}`)}>
      <Title level={3}>Customers</Title>
      <Table columns={customersColumns} dataSource={customersDataSource} />
    </Page>
  );
};

export default Sales;
