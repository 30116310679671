import React from 'react';
import { useDispatch } from 'react-redux';
import 'antd/dist/antd.css';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { clearState } from '../../features/User/UserSlice';
import { navbarLabels } from '../../constants';
import './index.scss';

const Navbar = () => {
  const { Header } = Layout;
  const dispatch = useDispatch();
  const onLogOut = () => {
    localStorage.removeItem('token');
    dispatch(clearState());
  };
  const params = useLocation();
  const currentMenuPath = navbarLabels.find(
    (menuLabel) => menuLabel.path === params.pathname
  );
  return (
    <Header className="header">
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[currentMenuPath?.path]}
      >
        {navbarLabels.map((menu) => {
          return (
            <Menu.Item key={`${menu.path}`}>
              <Link to={menu.path}>{menu.label} </Link>
            </Menu.Item>
          );
        })}
        <Menu.Item key={`${process.env.REACT_APP_API_BASE_URL}/admin`}>
          <a
            href={`${process.env.REACT_APP_API_BASE_URL}/admin`}
            target="_blank"
            rel="noreferrer"
          >
            Admin
          </a>
        </Menu.Item>
        <Menu.Item key="/login" onClick={onLogOut}>
          Log out
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default Navbar;
