import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../app/api';
import { actions, paths } from '../../constants';
import { apiPendingReducer, apiRejectedReducer } from '../../helpers';
import { baseThunk } from '../User/UserSlice';

export const getComponentTypes = createAsyncThunk(
  actions.COMPONENT_TYPES_GET,
  async (thunkAPI) => {
    return baseThunk(thunkAPI, api.get(paths.api.COMPONENT_TYPES));
  }
);

export const componentTypesSlice = createSlice({
  name: 'componentTypes',
  initialState: {
    allComponentTypes: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.allComponentTypes = null;

      return state;
    },
  },
  extraReducers: {
    [getComponentTypes.rejected]: apiRejectedReducer,
    [getComponentTypes.pending]: apiPendingReducer,
    [getComponentTypes.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.allComponentTypes = payload;
    },
  },
});

export const { clearState } = componentTypesSlice.actions;

export const componentTypesSelector = (state) => state.componentTypes;
